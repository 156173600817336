export default {
  "Please_sign_in": "Please sign in",
  "Email_address": "Email address",
  "Password": "Password",
  "Show_password": "Show Password",
  "Sign_in": "Sign in",
  "DashBoard": "DashBoard",
  "Wan": "WAN",
  "Lan": "LAN",
  "Firewall": "Firewall"
}