<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3 style="font-size: 20pt; color: #000000">分享清單</h3>
          <v-spacer />
          <v-col cols="12" sm="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          :footer-props="{
            'items-per-page-options': [20, 40, -1],
          }"
          class="elevation-1"
        >
          <template v-slot:[`item.check`]="{ item }">
            <v-icon class="mr-3" @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">確定要刪除?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteDialog = false">取消</v-btn>
            <v-btn color="green darken-1" text @click="DeleteGatewayShare()">確定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      sn: "",
      email: "",
      token: "",
      deleteDialog: false,
      search: "",
      headers: [
        {
          text: "sn",
          value: "sn",
          align: "center",
        },
        { text: "Name", value: "nickname", align: "center" },
        { text: "信箱(接收者)", value: "email", align: "center" },
        { text: "", value: "check", align: "center" },
      ],
      desserts: [],
      editedIndex: -1,
    };
  },
  created() {
    this.GetGatewayShare();
  },
  methods: {
    GetGatewayShare() {
      axios.get("/api/gateways").then((res) => {
        var data = res.data.data;
        var auth = localStorage.getItem("permission");
        if (auth == "admin") {
          axios.get("/api/gw/shareall").then((res) => {
            var allNewData = res.data.data;
            for (let i in data) {
              for (let j in allNewData) {
                if (data[i].sn == allNewData[j].sn) {
                  allNewData[j].nickname = data[i].nickname;
                }
              }
            }
            this.desserts = allNewData;
            console.log(this.desserts);
          });
        } else {
          var token = localStorage.getItem("token");
          var url = "/api/gw/share/" + token;
          axios.get(url).then((res) => {
            var newData = res.data.data;
            for (let i in data) {
              for (let j in newData) {
                if (data[i].sn == newData[j].sn) {
                  newData[j].nickname = data[i].nickname;
                }
              }
            }
            this.desserts = newData;
          });
        }
      });
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.editedIndex = this.desserts.indexOf(item);
      this.sn = item.sn;
      this.email = item.email;
      this.token = item.token;
    },
    DeleteGatewayShare() {
      var url = "/api/gw/sharedel/" + this.token + "/" + this.sn;
      var data = { email: this.email };
      axios.post(url, data, {}).then(() => {
        this.deleteDialog = false;
        Swal.fire("已刪除", "", "success");
        this.desserts.splice(this.editedIndex, 1);
      });
    },
  },
};
</script>
