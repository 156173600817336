import Vue from "vue";
import Router from "vue-router";

//componets
import Nav from "./components/Nav.vue";
import Headerbar from "./components/Headerbar.vue";
import Footer from "./components/Footer.vue";

//pages
import Login from "./pages/Login.vue";
// import Dashboard from "./pages/IDCdashboard.vue";
import Account from "./pages/IDCAccount.vue";
// import Device from "./pages/IDCDevice.vue";
import Linesetup from "./pages/IDClinesetup.vue";
import Eventsetup from "./pages/IDCEventsetup.vue";
import Gatewaylist from "./pages/IDCGatewaylist.vue";
import Gatewayshare from "./pages/IDCGatewayshare.vue";
import SN from "./pages/IDCSN.vue"
import WhiteList from "./pages/IDCWhiteList.vue"
//icon https://materialdesignicons.com/cdn/2.0.46/
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/",
    //   name: "狀態",
    //   icon: "mdi-format-align-justify",
    //   components: {
    //     default: Dashboard,
    //     nav: Nav,
    //     headerbar: Headerbar,
    //     footer: Footer,
    //   },
    //   props: {
    //     headerbar: {
    //       title: "狀態",//內標題
    //       data: "",
    //     },
    //   },
    //   meta: { authorize: ["admin", "user"] },
    // },
    // {
    //   path: "/sys",
    //   name: "系統狀態",
    //   icon: "mdi-format-align-justify",
    //   components: {
    //     default: Dashboard,
    //     nav: Nav,
    //     headerbar: Headerbar,
    //     footer: Footer,
    //   },
    //   props: {
    //     headerbar: {
    //       title: "系統狀態",//內標題
    //       data: "",
    //     },
    //   },
    //   meta: { authorize: ["admin"] },
    // },
    
    // {
    //   path: "/device",
    //   name: "設備列表",
    //   icon: "mdi-devices",
    //   components: {
    //     default: Device,
    //     nav: Nav,
    //     headerbar: Headerbar,
    //     footer: Footer,
    //   },
    //   props: {
    //     headerbar: {
    //       title: "設備列表",//內標題
    //       data: "",
    //     },
    //   },
    //   meta: { authorize: ["admin"] },
    // },
    //   meta: { authorize: ["admin", "user"] },
    // },
    {
      path: "/",
      name: "帳戶設定",
      icon: "mdi-account",
      components: {
        default: Account,
        nav: Nav,
        headerbar: Headerbar,
        footer: Footer,
      },
      props: {
        headerbar: {
          title: "帳戶設定",//內標題
          data: "",
        },
      },
      meta: { authorize: ["admin"] },
    },
    {
      path: "/gatewaylist",
      name: "GateWay列表",
      icon: "mdi-tools",
      components: {
        default: Gatewaylist,
        nav: Nav,
        headerbar: Headerbar,
        footer: Footer,
      },
      props: {
        headerbar: {
          title: "GateWay列表",//內標題
          data: "",
        },
      },
      meta: { authorize: ["user"] },
    },
    {
      path: "/gateway",
      name: "分享清單",
      icon: "mdi-share-variant",
      components: {
        default: Gatewayshare,
        nav: Nav,
        headerbar: Headerbar,
        footer: Footer,
      },
      props: {
        headerbar: {
          title: "分享清單",//內標題
          data: "",
        },
      },
      meta: { authorize: ["admin", "user"] },
    },
    {
      path: "/sn",
      name: "已開通序號",
      icon: "mdi-format-list-bulleted-square",
      components: {
        default: SN,
        nav: Nav,
        headerbar: Headerbar,
        footer: Footer,
      },
      props: {
        headerbar: {
          title: "已開通序號",//內標題
          data: "",
        },
      },
      meta: { authorize: ["admin"] },
    },
    {
      path: "/whitelist",
      name: "序號清單",
      icon: "mdi-share-variant",
      components: {
        default: WhiteList,
        nav: Nav,
        headerbar: Headerbar,
        footer: Footer,
      },
      props: {
        headerbar: {
          title: "序號清單",//內標題
          data: "",
        },
      },
      meta: { authorize: ["admin"] },
    },
    {
      path: "/notify",
      name: "Line推播設定",
      icon: "mdi-message-text",
      components: {
        default: Linesetup,
        nav: Nav,
        headerbar: Headerbar,
        footer: Footer,
      },
      props: {
        headerbar: {
          title: "Line推播設定",
          data: "",
        },
      },
      meta: { authorize: ["user"] },
    },
    {
      path: "/event",
      name: "事件訊息",
      icon: "mdi-message-text",
      components: {
        default: Eventsetup,
        nav: Nav,
        headerbar: Headerbar,
        footer: Footer,
      },
      props: {
        headerbar: {
          title: "事件訊息",
          data: "",
        },
      },
      meta: { authorize: ["user"] },
    },

    {
      path: "/Login",
      name: "登出",
      icon: "mdi-logout-variant",
      component: Login,
      meta: { authorize: ["admin", "user"] },
    },
    // {
    //   path: "*",
    //   redirect: "/",
    //   meta: { authorize: ["admin", "user"] },
    // },

  ],
});
