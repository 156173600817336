<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3 style="font-size: 20pt; color: #000000">白名單</h3>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-btn large outlined class="blue--text" @click="addDialog = true"> ADD </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          :footer-props="{
            'items-per-page-options': [20, 40],
          }"
          :items-per-page="20"
          class="elevation-1"
        >
          <template v-slot:[`item.used`]="{ item }">
            <div v-if="item.used">
              <v-icon color="blue">mdi-check-circle</v-icon>
            </div>
            <div v-else>
              <v-icon color="red">mdi-close-circle</v-icon>
            </div>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <!-- <v-icon class="mr-6" @click="editedItem(item)">mdi-lead-pencil</v-icon> -->
                        <!-- <v-icon v-if="!item.used" class="ml-6" @click="removeItem(item)" -->
            <v-icon  class="ml-6" @click="removeItem(item)"
              >mdi-trash-can</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-dialog v-model="addDialog" width="450">
      <v-card>
        <v-card-title> ADD White List </v-card-title>
        <v-card-text>
          <v-text-field v-model="sn"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="Close()">cancel</v-btn>
          <v-btn text class="blue--text" @click="addWhiteList">apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" width="450">
      <v-card>
        <v-card-title> Edit </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="editItem.sn"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-radio-group v-model="editItem.used" row mandatory>
              <v-col>
                <span>是否開通</span>
              </v-col>
              <v-col>
                <v-radio label="是" :value="true"></v-radio>
              </v-col>
              <v-col>
                <v-radio label="否" :value="false"></v-radio>
              </v-col>
            </v-radio-group>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="Close()">cancel</v-btn>
          <v-btn text class="blue--text" @click="editWhiteList">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeDialog" width="450">
      <v-card>
        <v-card-title> Remove This White List? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="Close()">cancel</v-btn>
          <v-btn text class="red--text" @click="removeWhiteList">remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      addDialog: false,
      editDialog: false,
      removeDialog: false,
      sn: null,
      search: "",
      headers: [
        {
          text: "sn",
          value: "sn",
          align: "center",
        },
        { text: "狀態", value: "used", align: "center" },
        { text: "", value: "edit", align: "center", sortable: false },
      ],
      desserts: [],
      editItem: {},
      editIndex: -1,
    };
  },
  created() {
    this.getWhiteList();
  },
  watch: {
    addDialog(isOpen) {
      if (!isOpen) this.Close();
    },
  },
  methods: {
    getWhiteList() {
      axios.get("/api/sns/").then((res) => {
        console.log(res.data);
        this.desserts = res.data.data;
      });
    },
    addWhiteList() {
      var count = 0;
      var Query = {
        sn: this.sn,
      };
      for (let i in this.desserts) {
        if (this.desserts[i].sn == this.sn) {
          count++;
        }
      }
      if (count == 0) {
        axios.post("/api/sn", Query).then((res) => {
          Swal.fire("已新增", "", "success");
          this.desserts.push(res.data.data);
        });
      } else Swal.fire("重複SN", "", "error");
      this.Close();
    },
    editedItem(item) {
      this.editDialog = true;
      this.editIndex = this.desserts.indexOf(item);
      this.editItem = Object.assign({}, item);
    },
    editWhiteList() {
      Object.assign(this.desserts[this.editIndex], this.editItem);
      this.Close();
    },
    removeItem(item) {
      this.removeDialog = true;
      this.editIndex = this.desserts.indexOf(item);
      this.editItem = item;
      console.log(this.editItem, this.editIndex, item);
    },
    removeWhiteList() {
      axios.delete("/api/sn/" + this.editItem.sn).then(() => {
        Swal.fire("已刪除", "", "success");
        this.desserts.splice(this.editIndex, 1);
        this.Close();
      });
    },
    Close() {
      this.addDialog = false;
      this.editDialog = false;
      this.removeDialog = false;
      this.sn = null;
    },
  },
};
</script>
