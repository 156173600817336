<template>
  <v-app id="inspire">
    <span class="bg"></span>

    <v-main>
      <v-container class="fill-height" fluid>
        <v-card width="400" class="mx-auto mt-5 color" @keyup.enter="login">
          <v-card-title class="pb-0">
            <h3>Login</h3>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Username"
                prepend-icon="mdi-account-circle"
                v-model="userName"
              />
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                prepend-icon="mdi-lock"
                append-icon="mdi-eye-off"
                v-model="password"
                @click:append="showPassword = !showPassword"
              />
            </v-form>
            <v-btn text @click="FgPwDialog = true"> 忘記密碼? </v-btn>
            <!-- <v-btn text @click="RegistDialog=true">
              註冊帳戶
            </v-btn> -->
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <!--
            <v-btn color="success">Forget Password</v-btn>
            -->
            <v-spacer></v-spacer>

            <v-btn color="info" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
      <!-- --------------------------------忘記密碼------------------------------------- -->
      <v-dialog v-model="FgPwDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">請輸入Email及新密碼</v-card-title>
          <v-card-text class="subtitle-1 font-weight-bold">
            <v-container>
              <v-row>
                <v-text-field v-model="email" type="text" label="Email"></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="password"
                  type="text"
                  label="New Password"
                ></v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="FgPwDialog = false">取消</v-btn>
            <v-btn color="green darken-1" text @click="ForgetPw">確定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- --------------------------------註冊-------------------------------------- -->
      <v-dialog v-model="RegistDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">註冊新帳戶</v-card-title>
          <v-card-text class="subtitle-1 font-weight-bold">
            <v-container>
              <v-row>
                <v-text-field v-model="email" type="text" label="Email"></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="password"
                  type="text"
                  label="Password"
                ></v-text-field>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="RegistDialog = false">取消</v-btn>
            <v-btn color="green darken-1" text @click="Regist">確定</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      showPassword: false,
      FgPwDialog: false,
      RegistDialog: false,
      token: "",
      password: "",
      userName: "",
      email: "",
    };
  },
  created() {
    localStorage.clear();
  },
  methods: {
    loginold() {
      if (this.userName == "admin" && this.password == "admin") {
        localStorage.setItem("token", "ImLogin");
        this.$router.push("/");
      } else {
        alert("login failed");
      }
    },
    login() {
      var url = "/api/user/login";
      var data = { email: this.userName, password: this.password };
      axios
        .post(url, data, {})
        .then(function (response) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("permission", response.data.permission);
          //console.log(response.data.permission)
          //alert(response.data)
          if (response.data.permission == "admin") {
            window.location.href = "/";
          } else {
            window.location.href = "/gatewaylist";
          }
        })
        .catch(function (error) {
          alert("Account Error");
          console.log(error);
        });
    },
    ForgetPw() {
      var url = "/api/user/fgpassword";
      var data = { email: this.email, password: this.password };
      console.log(data);
      axios.post(url, data, {}).then((res) => {
        console.log(res);
        Swal.fire("密碼更新成功，請至信箱開通帳戶", "", "success");
        this.RegistDialog = false;
        this.email = "";
        this.password = "";
      });
    },
    Regist() {
      var url = "/api/user/create";
      var data = { email: this.email, password: this.password };
      axios
        .post(url, data, {})
        .then((res) => {
          console.log(res);
          Swal.fire("註冊成功，請至信箱開通帳戶", "", "success");
          this.RegistDialog = false;
          this.email = "";
          this.password = "";
        })
        .catch(() => {
          Swal.fire("此Email已被註冊", "", "error");
          this.RegistDialog = false;
          this.email = "";
          this.password = "";
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/login.jpg") no-repeat center center;
  background-size: cover;
  background-color: red;
  transform: scale(1.1);
  opacity: 0.8;
}

.transparent {
  background-color: white !important;
  opacity: 0.65;
  border-color: transparent !important;
}

.color {
  background-color: ghostwhite;
}
</style>
