<template>
  <v-app-bar app color="#90A4AE" dark>
    <v-app-bar-nav-icon @click.stop="changeDrawer"></v-app-bar-nav-icon>
    <div >
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    </div>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { bus } from "../main";

export default {
  props: ["title"],

  data: () => ({
    drawer: true,
    //title: null
    isAdmin: null,
  }),
  created() {
    this.checkIsAdmin();
  },
  methods: {
    changeDrawer() {
      //alert(this.drawer);
      this.drawer = !this.drawer;
      bus.$emit("changeIt", this.drawer);

      return this.drawer;
    },
    reboot() {
      //alert("Apply");
      window.location.href = "/Load";
    },
    checkIsAdmin() {
      var auth = localStorage.getItem("permission");
      if (auth == "admin") {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },
  },
  mounted() {
    //alert(this.$store.state.idcset.title);
    //this.title = this.$store.state.idcset.title;
  },
};
</script>
