<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <!---->

      <v-img
        class="white--text align-end"
        height="150px"
        src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
      >
        <!-- <v-card-title>IDC Server</v-card-title> -->
      </v-img>

      <!---------------------------------------------------------------------------------------->
      <!-- 選擇2選擇2
      <v-img
        src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
        height="100px"
        dark
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="white--text title ">
              IDC Server
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">
              tasean833@gmail.com
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-img>
      -->

      <!---------------------------------------------------------------------------------------->
      <v-divider></v-divider>
      <!---------------------------------------------------------------------------------------->

      <v-list dense>
        <div v-for="list in lists" :key="list.path">
          <v-list-item @click="goToHref(list.path)">
            <v-list-item-action>
              <v-icon>{{ list.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ list.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="dialog" max-width="450px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Are you sure to logout?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="#1b1c1d" text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="#2185d0" text to="/Login"> Logout </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { bus } from "../main";
import router from "../router";

export default {
  component: {},
  props: {},
  data() {
    return {
      dialog: false,
      drawer: true,
      lists: router.options["routes"],
    };
  },
  created() {
    //var idx=[];
    var idx;
    var permission = localStorage.getItem("permission");
    //alert("Nav.vue create")
    this.lists.forEach(function (item, index) {
      if (item.path === "/Load") {
        idx = index;
        //console.log(index)
      }
    });

    //this.lists.splice(idx, 1);
    idx = [];
    this.lists.forEach(function (item, index) {
      if (!item.meta.authorize.includes(permission)) {
        //console.log("No Permission");
        idx.push(index);
      }
    });

    var ridx = idx.reverse();
    //ridx.forEach((element) => console.log(element));
    ridx.forEach((element) => this.lists.splice(element, 1));

    //縮排
    bus.$on("changeIt", (drawer) => {
      this.drawer = drawer;
    });
  },
  methods: {
    //倒頁
    goToHref(path) {
      if (path == "/Login") this.dialog = true;
      else location.href = path;
    },
  },
};
</script>
<style>
html {
  overflow-y: auto;
}
</style>
