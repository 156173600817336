<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3 style="font-size: 20pt; color: #000000">Gateway 列表</h3>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          class="elevation-1"
        />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "sn",
          value: "sn",
          align: "center",
        },
        { text: "Nick Name", value: "nickname", align: "center" },
      ],
      desserts: [],
    };
  },
  created() {
    this.gatewayList();
  },
  methods: {
    gatewayList() {
      var token = localStorage.getItem("token");
      axios.get("/api/gateway/" + token).then((res) => {
        console.log(res.data);
        this.desserts = res.data.data;
      });
    },
  },
};
</script>
