<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn @click="AddDialog = true">新增設定</v-btn>
        </v-card-title>
        <v-card-text
          ><v-data-table
            :headers="headers"
            :items="desserts"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-3" @click="editItem(item)"> mdi-pencil </v-icon>
              <v-icon class="mr-3" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table></v-card-text
        >
      </v-card>
    </v-container>
    <!-- --------------------------------新增------------------------------------- -->
    <v-dialog v-model="AddDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">設定</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.sn" label="SN"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t1" label="T1"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t2" label="T2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t3" label="T3"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t4" label="T4"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t5" label="T5"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t6" label="T6"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t7" label="T7"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t8" label="T8"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t9" label="T9"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="addItem.t10" label="T10"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="AddDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- --------------------------------修改------------------------------------- -->
    <v-dialog v-model="EditDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">更改設定</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t1" label="T1"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t2" label="T2"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t3" label="T3"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t4" label="T4"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t5" label="T5"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t6" label="T6"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t7" label="T7"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t8" label="T8"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="6">
                <v-text-field v-model="editedItem.t9" label="T9"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="6">
                <v-text-field
                  v-model="editedItem.t10"
                  label="T10"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="EditDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="update()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      AddDialog: false,
      EditDialog: false,
      headers: [
        {
          text: "SN",
          align: "start",
          sortable: false,
          value: "sn",
        },
        { text: "T1", value: "t1", sortable: false },
        { text: "T2", value: "t2", sortable: false },
        { text: "T3", value: "t3", sortable: false },
        { text: "T4", value: "t4", sortable: false },
        { text: "T5", value: "t5", sortable: false },
        { text: "T6", value: "t6", sortable: false },
        { text: "T7", value: "t7", sortable: false },
        { text: "T8", value: "t8", sortable: false },
        { text: "T9", value: "t9", sortable: false },
        { text: "T10", value: "t10", sortable: false },
        { text: "編輯", value: "actions", sortable: false },
      ],
      desserts: [],
      addItem: {},
      editedItem: {},
      editedIndex: -1,
    };
  },
  mounted() {
    //   this.intervalId = setInterval(() => {
    //   this.usertoken = localStorage.getItem("token");
    //   axios
    //     .get("/api/notify/gw/" + this.usertoken, {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((res) => {
    //       this.desserts = res.data.data;
    //     });
    // }, 1000);
    this.usertoken = localStorage.getItem("token");
    axios
      .get("/api/notify/gw/" + this.usertoken, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.desserts = res.data.data;
      });
  },
  methods: {
    save() {
      var token = localStorage.getItem("token");
      var url = "/api/notify/gw/" + token + "/" + this.addItem.sn;
      if (this.desserts.length == 0) {
        axios.post(url, this.addItem).then(() => {
          this.desserts.push(this.addItem);
          Swal.fire("已新增", "", "success");
          this.AddDialog = false;
          this.addItem = {};
        });
      } else {
        var checklist = [];
        for (var i = 0; i < this.desserts.length; i++) {
          if (this.desserts[i].sn == this.addItem.sn) {
            checklist.push(this.desserts[i]);
          }
        }
        if (checklist.length > 0) {
          Swal.fire("重複sn", "", "error");
          this.AddDialog = false;
          this.addItem = {};
        } else {
          axios.post(url, this.addItem).then(() => {
            this.desserts.push(this.addItem);
            Swal.fire("已新增", "", "success");
            this.AddDialog = false;
            this.addItem = {};
          });
        }
      }
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.EditDialog = true;
    },
    update() {
      var token = localStorage.getItem("token");
      var url = "/api/notify/gw/" + token + "/" + this.editedItem.sn;
      console.log(this.editedItem);
      axios.post(url, this.editedItem).then(() => {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
        Swal.fire("已更新", "", "success");
        this.EditDialog = false;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      var token = localStorage.getItem("token");
      var url = "/api/notify/gw/" + token + "/" + item.sn;
      axios.delete(url, item).then(() => {
        this.desserts.splice(this.editedIndex, 1);
        Swal.fire("已刪除", "", "success");
      });
    },
  },
};
</script>
