import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

/*
let store = new Vuex.Store({
  state: {
    userName: null,
    userEmail: null,
    userAvatar: null,
  },
  mutations: {

  },
  actions: {

  }
})
export default store
*/

/*
 * State 狀態儲存的物件，Vuex 使用 單一狀態樹 的方式來存放。
 * Getters 取得狀態資料的方法。
 * Mutations 更新狀態資料的方法。
 * Actions 類似 Mutations，但是 Actions 是呼叫 Mutations，且可支援非同步呼叫。
 * Modules 用於分割 Vuex 的區塊。
 * ------------------------------------------------------- *
 * mapState 可以取得 state 裡面的資料。(computed)
 * mapMutations 可以取得 mutations 裡面的方法。(methods)
 * mapActions 可以取得 actions 裡面的方法。(methods)
 * mapGetters 可以取得 getters 裡面的方法。(computed)
 * 
  computed: {
    ...mapGetters([
      'getAge'
    ]),
    ...mapState([
      'age'
    ])
  },
  methods: {
    ...mapActions([
      'ohMyAge'
    ]),
    ...mapMutations([
      'incrementAge'
    ])
  }
*/


const idcuser = {
  state: {
    token: null,
    auth: false
    // 狀態資料
  },
  mutations: {
    // 更新資料狀態
    setToken: function (state, token) {
      state.token = token
    }
  },
  actions: {
    //類似 Mutations 非同步
  },
  getters: {
    //取得狀態資料
    getToken: function (state) {
      return state.token
    }
  }
}

const idcset = {
  state: {
    // 狀態資料
    title: "title",
  },
  mutations: {
    // 更新資料狀態
    setTitle: function (state, title) {
      state.title = title
    }
  },
  actions: {
    //類似 Mutations 非同步
  },
  getters: {
    //取得狀態資料
  }
}
let store = new Vuex.Store({
  modules: {
    // 這邊就放你要的 Modules
    user: idcuser,
    set: idcset
  }
})

export default store