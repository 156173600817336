export default {
  "Please_sign_in": "請登入。",
  "Email_address": "輸入電子郵件",
  "Password": "輸入密碼",
  "Show_password": "顯示密碼",
  "Sign_in": "登入",
  "DashBoard": "儀表板",
  "Wan": "外部網路",
  "Lan": "區域網路",
  "Firewall": "防火牆"
}