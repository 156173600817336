<template>
  <v-main>
    <v-form>
      <v-container>
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            sm5
            md12
          >
            <v-flex
              xs12
              sm5
              md12
            >
              <v-text-field
                v-model="lineurl"
                label="Callback URL 填入Line官網"
                disabled
                autofocus
              ></v-text-field>
              <!--
              <v-btn color="success" @click.stop.prevent="copylineURL"
                >複製</v-btn
              >
              <input type="hidden" id="testing-code" :value="lineurl" />
              -->
            </v-flex>
            <br />
            <v-btn
              color="primary"
              small
              dark
              href="https://notify-bot.line.me/zh_TW/"
              target="_blank"
            >
              <v-icon>mdi-pencil</v-icon>
              前往 Line Notify 網站設定
            </v-btn>
            <!--
            <v-flex xs12 sm5 md12>
              <v-btn
                icon
                href="https://notify-bot.line.me/zh_TW/"
                target="_blank"
              >
              </v-btn>
            </v-flex>
            -->
          </v-flex>
          <v-divider></v-divider>
          <v-flex
            xs12
            sm5
            md12
          >
            <v-text-field
              v-model="id"
              label="Line ID (Line提供)"
              required
            ></v-text-field>
          </v-flex>
          <v-flex
            xs12
            sm5
            md12
          >
            <v-text-field
              v-model="secret"
              label="Line Secret (Line提供)"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout
          align-end
          justify-end
          fill-height
        >
          <v-btn
            color="info"
            @click="save"
          >綁定</v-btn>
          <v-btn
            color="success"
            @click="lineTest"
          >LineNotify測試</v-btn>
        </v-layout>
      </v-container>
    </v-form>
  </v-main>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  component: {},
  props: {
    source: String,
  },
  data: () => ({
    url: "",
    linetoken: null,
    usertoken: null,
    id: "",
    secret: "",
    lineurl: "http://" + window.location.host + "/api/line/callback/", //由系統提供
    data: null,
  }),
  methods: {
    lineTest () {
      var msg = "LineNotify測試";
      //alert("@@" + mac + "##" + msg);

      axios
        .get("/api/line/notifymsg/" + this.usertoken + "?msg=" + msg)
        .then(function () {
          Swal("成功", "", "success");
        })
        .catch(function () {
          Swal("失敗", "", "error");
        });
    },

    save () {
      //console.log("ID:" + this.id);
      //console.log("Secret:" + this.secret);

      var pdata = {
        id: this.id,
        secret: this.secret,
        cburl: this.lineurl,
        linetoken: this.linetoken,
        usertoken: this.usertoken,
      };
      axios
        .post("/api/line/set/" + this.usertoken, pdata, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then({})
        .catch({})
        .finally({});

      this.lineauth();
    },

    copylineURL () {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var type = successful ? "successful" : "error";
        Swal("拷貝成功", "", type);
      } catch (err) {
        Swal("拷貝失敗", "", type);
      }

      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },

    lineauth () {
      //先將資料save

      var URL = "https://notify-bot.line.me/oauth/authorize?";
      URL += "response_type=code";
      URL += "&client_id=" + this.id;
      URL += "&redirect_uri=" + this.lineurl;
      URL += "&scope=notify";
      URL += "&state=NO_STATE";
      URL += "&response_mode=POST";
      //window.location.href = URL
      window.open(URL);
    },
  },
  created () {
    if (this.$route.query.state == "ok") {
      Swal.fire("Line註冊成功", "", "success");
    }
  },
  mounted () {
    this.usertoken = localStorage.getItem("token");
    console.log(this.usertoken);
    this.lineurl = this.lineurl + this.usertoken;
    axios
      .get("/api/line/get/" + this.usertoken, {
        headers: {
          "Content-Type": "application/json",
          //"x-api-token": this.token
        },
      })
      .then((res) => {
        console.log("res=>", res);
        this.id = res.data.id;
        this.secret = res.data.secret;
        this.cburl = res.data.cburl;
        this.linetoken = res.data.linetoken;
      })
      .catch(() => { });
  },
};
</script>
